<template>
  <div class="layout-all">
    <BlockLayoutHeaderDefault
      v-if="user"
      :logos="campaignData.logos"
      :promo-link="campaignData.landingPageUrl"
      :promo-link-text="campaignData.landingpageButtonText"
      :links="campaignData.headerLinks"
      :user-links="campaignData.userLinks"
    />

    <BlockLayoutHeaderSimple
      v-else
      :promo-link="campaignData.landingPageUrl"
      :promo-link-text="campaignData.landingpageButtonText"
    />

    <suspense>
      <slot />
    </suspense>
  </div>
</template>

<script setup>
const campaignData = inject('campaignData');
</script>

<style lang="scss" scoped>
.layout-all {
    padding-top: calc(var(--header-height) + 60px);
    padding-bottom: calc(var(--header-height) + 60px);
}
</style>
